import { render, staticRenderFns } from "./IconPrint.vue?vue&type=template&id=51c59fa4"
import script from "./IconPrint.vue?vue&type=script&lang=ts"
export * from "./IconPrint.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports